import React from 'react'
import Link from 'gatsby-link'
import Header from '../components/Header'
import Contacts from '../components/Contacts'
import Services from '../components/Services'
import SS from '../components/Services/ss'
import { Grid, Row, Col } from 'react-flexbox-grid'
import Helmet from 'react-helmet'
import VVS from '../components/Services/vvs'

const VVSPage = () => (
  <div>
       <Helmet
    title="Savitarnos sistemų kūrimas"
    />
    <Header content={<div><h1>Savitarnos sistemų kūrimas</h1><hr /><h2>Geresnis klientų aptarnavimas</h2></div>} />
      <div className="holder" css={{padding: '50px 0'}}>
          <Grid fluid>
            <Row>
              <Col xs={12} lg={7}>
              <section className="service">
                <h1>Savitarnos sistemų kūrimas</h1>
                <p>
                    Jei aptarnaujate daug klientų, automatiškai susiduriate su dideliais informacijos srautais, kurių apdorojimas reikalauja nemažų žmogiškųjų išteklių, jei tai darote rankiniu būdu. Daugelis su klientų aptarnavimu susijusių procesų gali būti automatizuoti. Pateikiame keletą pavyzdžių, kokios yra savitarnos sistemų galimybės (jas galima derinti tarpusavyje). 
                </p>

                
                <p>
                <h2>Informacijos surinkimas iš klientų</h2>
Saugi klientų zona, kurioje gali būti rengiamos apklausos, priimami užsakymai, renkama specifinė informacija. 

                </p>

                <p>
                <h2>Paslaugų ir prekių konfigūratoriai</h2>
                Remiantis kliento duomenimis galima automatiškai siūlyti klientui tinkamiausias prekes ar paslaugas.                
                </p>

                <p>
                <h2>Klientų užsakymų sekimas</h2>
                Patogi sistema klientų užsakymams priimti su galimybe stebėti užsakymų statusą, aptikti pasyvius klientus ir imtis veiksmų siekiant juos paversti aktyviais klientais. 

                </p>

                <p>
                <h2>Mokesčių surinkimas ir administravimas</h2>
                Galime sukurti sistemą, kuri automatiškai apskaičiuos mėnesinius kintamus arba fiksuotus mokesčius, generuos ir siųs sąskaitas, administruos skolas. 
                </p>

                <p>
                <h2>Vartotojų savipagalbos informacinės sistemos</h2>
                Kuriame vartotojų gidus ir vartotojų grįžtamojo ryšio sistemas (klausimai, pranešimai apie problemas ir kt.).
                </p>

                <p>
                <h2>Sujungimas su mokėjimo platformomis</h2>
                Sistemas galime sujungti su jūsų poreikius atitinkančiomis mokėjimo platformomis: Braintreepayments, Paypal, Stripe, GoCardless, Paysera, Banklink.
                </p>
                <p>
                Kiek ir kokių funkcijų reikia jums, priklauso nuo jūsų verslo specifikos. Mūsų komanda pasiruošusi išanalizuoti jūsų įmonės poreikius ir pasiūlyti tinkamiausią verslo sistemos variantą.

                </p>
              </section>
              </Col>
              <Col xs={12} lg={5}>
                <div className="insideBlock">
                  <VVS />
                </div>
              </Col>
            </Row>
          </Grid>
        
    </div>
    <Contacts />
  </div>
)

export default VVSPage

